<template>
  <div class="slider" :class="{ 'close-anim': closeAnim }">
    <RFModal v-if="isShowModal" @close="close">
      <vueper-slides
        ref="vueperslides1"
        :touchable="false"
        fade
        :autoplay="false"
        :bullets="false"
        class="slider-big"
        fixed-height="100%"
      >
        <template #arrow-left>
          <Icons icon-name="arrow-left" />
        </template>

        <template #arrow-right>
          <Icons icon-name="arrow-right" />
        </template>
        <vueper-slide
          v-for="(slide, i) in slides"
          :key="i"
          :image="slide.image"
          class="slider-big__slide"
        >
        </vueper-slide>
      </vueper-slides>
    </RFModal>

    <span class="slider__title">Галерея ЖК</span>

    <vueper-slides
      class="no-shadow thumbnails slider-mini"
      ref="vueperslides2"
      @slide="
        $refs.vueperslides1.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      :visible-slides="3"
      :bullets="false"
      :touchable="false"
      fixed-height="110px"
      :gap="2.5"
      :arrows="false"
    >
      <vueper-slide
        class="slider-mini__slide"
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        @click.native="openModal(i)"
      >
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import RFModal from '@/components/atoms/Modals/RFModal.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Icons from '@/components/atoms/Icons/index.vue'

export default {
  name: 'Slider',
  components: {
    Icons,
    RFModal,
    VueperSlides,
    VueperSlide,
  },
  props: {},
  data() {
    return {
      isShowModal: false,
      closeAnim: false,
      slides: [
        {
          image: require('@/assets/images/third/1.webp'),
        },
        {
          image: require('@/assets/images/third/2.webp'),
        },
        {
          image: require('@/assets/images/third/3.webp'),
        },
        {
          image: require('@/assets/images/third/4.webp'),
        },
        {
          image: require('@/assets/images/third/5.webp'),
        },
        {
          image: require('@/assets/images/third/6.webp'),
        },
        {
          image: require('@/assets/images/third/7.webp'),
        },
        {
          image: require('@/assets/images/third/8.webp'),
        },
        {
          image: require('@/assets/images/third/9.webp'),
        },
        {
          image: require('@/assets/images/third/10.webp'),
        },
        {
          image: require('@/assets/images/third/11.webp'),
        },
        {
          image: require('@/assets/images/third/12.webp'),
        },
        {
          image: require('@/assets/images/third/13.webp'),
        },
        {
          image: require('@/assets/images/third/14.webp'),
        },
      ],
    }
  },
  watch: {
    isShowModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
  },
  methods: {
    openModal(i) {
      this.isShowModal = true
      // фикс ошибки (модалка не успевала открываться)
      setTimeout(() => {
        this.$refs.vueperslides1.goToSlide(i)
      }, 1)
    },
    close() {
      this.closeAnim = true
      setTimeout(() => {
        this.isShowModal = !this.isShowModal
        this.closeAnim = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.slider {
  // фикс бага отображения undefined на карточках
  font-size: 0;
  width: 540px;
  padding-bottom: 10px;

  @include mobile {
    background-color: $white !important;
    width: calc(100vw - 30px);
  }

  &-mini {
    @include mobile {
      width: 100%;
      background-color: $white !important;
      overflow: hidden;
    }
    &__slide {
      z-index: 1;
      position: relative;
      border: 1.5px solid rgba(255, 255, 255, 0.3);
      filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.5));
      border-radius: 10px;

      @include mobile {
        filter: none;
        width: calc((100vw - 30px - 4%) / 3) !important;
      }

      &:nth-child(n + 3) {
        &::after {
          content: '+14';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
          background: rgba(255, 255, 255, 0.9);
          text-align: center;
          padding-top: 15%;
          @include font-styles(42px, 50px, 600, $c-dark);

          @include mobile {
            @include font-styles(28px, 30px, 600, $c-dark);
          }
        }
        &::before {
          content: 'фотографий';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 6;
          padding-top: 38%;
          text-align: center;
          @include font-styles(18px, 24px, 600, $c-dark);

          @include mobile {
            @include font-styles(14px, 20px, 600, $c-dark);
          }
        }
      }
    }
  }

  &-big {
    border-radius: 40px;
    overflow: hidden;

    @include mobile {
      border-radius: 20px;
    }

    &__slide {
      height: 100%;
    }
  }

  &__title {
    padding-bottom: 5px;
    display: inline-block;
    @include font-styles(26px, 36px, 600);

    @include mobile {
      @include font-styles(16px, 20px, 600, $c-dark);
    }
  }
}
.thumbnails {
  margin: auto;
  max-width: 540px;

  @include mobile {
    margin: 0;
    max-width: calc(100vw - 30px) !important;
  }
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  overflow: hidden;
  cursor: pointer;

  @include mobile {
    opacity: 0.9;
  }
}

// * Стили для стрелок

::v-deep .vueperslides__arrow {
  opacity: 0.9;
  &--prev {
    left: 32px;
    @include mobile {
      left: 10px;
    }
  }
  &--next {
    right: 32px;
    @include mobile {
      right: 10px;
    }
  }
}

// хардкод слайдера, т.к. адаптивные брекпоинты слайдера отрабатывают через раз

::v-deep .slider-mini.vueperslides {
  @include mobile {
    overflow: hidden;
  }
  &--fixed-height {
    @include mobile {
      height: 84px !important;
    }
  }
}

::v-deep .modal__body {
  border: none;
}
</style>
